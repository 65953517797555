@font-face {
  font-family: "Swis721 BT";
  src: url("../fonts/Swiss721BT-Light/Swiss721BT-Light.eot");
  src: url("../fonts/Swiss721BT-Light/Swiss721BT-Light.woff") format("woff"),
    url("../fonts/Swiss721BT-Light/Swiss721BT-Light.ttf") format("truetype"),
    url("../fonts/Swiss721BT-Light/Swiss721BT-Light.svg") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Swis721 BT";
  src: url("../fonts/Swiss721BT-Roman/Swiss721BT-Roman.eot");
  src: url("../fonts/Swiss721BT-Roman/Swiss721BT-Roman.woff") format("woff"),
    url("../fonts/Swiss721BT-Roman/Swiss721BT-Roman.ttf") format("truetype"),
    url("../fonts/Swiss721BT-Roman/Swiss721BT-Roman.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Swis721 BT";
  src: url("../fonts/Swiss721BT-Bold/Swiss721BT-Bold.eot");
  src: url("../fonts/Swiss721BT-Bold/Swiss721BT-Bold.woff") format("woff"),
    url("../fonts/Swiss721BT-Bold/Swiss721BT-Bold.ttf") format("truetype"),
    url("../fonts/Swiss721BT-Bold/Swiss721BT-Bold.svg") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Swis721 BT";
  src: url("../fonts/Swiss721BT-BoldCondensed/Swiss721BT-BoldCondensed.eot");
  src: url("../fonts/Swiss721BT-BoldCondensed/Swiss721BT-BoldCondensed.woff")
      format("woff"),
    url("../fonts/Swiss721BT-BoldCondensed/Swiss721BT-BoldCondensed.ttf")
      format("truetype"),
    url("../fonts/Swiss721BT-BoldCondensed/Swiss721BT-BoldCondensed.svg")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
}

@font-face {
  font-family: "Swis721 BT";
  src: url("../fonts/Swiss721BT-BoldCondensedItalic/fonts/swiss-721-bold-condensed-italic-bt-70603.eot");
  src: url("../fonts/Swiss721BT-BoldCondensedItalic/fonts/swiss-721-bold-condensed-italic-bt-70603.woff")
      format("woff"),
    url("../fonts/Swiss721BT-BoldCondensedItalic/fonts/swiss-721-bold-condensed-italic-bt-70603.ttf")
      format("truetype"),
    url("../fonts/Swiss721BT-BoldCondensedItalic/fonts/swiss-721-bold-condensed-italic-bt-70603.svg")
      format("svg");
  font-weight: bold;
  font-style: italic;
  font-stretch: condensed;
}

@font-face {
  font-family: "Swis721 BT";
  src: url("../fonts/Swiss721BT-ItalicCondensed/fonts/swiss-721-condensed-italic-bt.eot");
  src: url("../fonts/Swiss721BT-ItalicCondensed/fonts/swiss-721-condensed-italic-bt.woff")
      format("woff"),
    url("../fonts/Swiss721BT-ItalicCondensed/fonts/swiss-721-condensed-italic-bt.ttf")
      format("truetype"),
    url("../fonts/Swiss721BT-ItalicCondensed/fonts/swiss-721-condensed-italic-bt.svg")
      format("svg");
  font-weight: normal;
  font-style: italic;
  font-stretch: condensed;
}

@font-face {
  font-family: "Swis721 BT";
  src: url("../fonts/Swiss721BT-LightCondensedItalic/fonts/swiss-721-light-condensed-italic-bt.eot");
  src: url("../fonts/Swiss721BT-LightCondensedItalic/fonts/swiss-721-light-condensed-italic-bt.woff")
      format("woff"),
    url("../fonts/Swiss721BT-LightCondensedItalic/fonts/swiss-721-light-condensed-italic-bt.ttf")
      format("truetype"),
    url("../fonts/Swiss721BT-LightCondensedItalic/fonts/swiss-721-light-condensed-italic-bt.svg")
      format("svg");
  font-weight: 200;
  font-style: italic;
  font-stretch: condensed;
}

@font-face {
  font-family: "Swis721 BT";
  src: url("../fonts/Swiss721BT-RomanCondensed/Swiss721BT-RomanCondensed.eot");
  src: url("../fonts/Swiss721BT-RomanCondensed/Swiss721BT-RomanCondensed.woff")
      format("woff"),
    url("../fonts/Swiss721BT-RomanCondensed/Swiss721BT-RomanCondensed.ttf")
      format("truetype"),
    url("../fonts/Swiss721BT-RomanCondensed/Swiss721BT-RomanCondensed.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
}

@font-face {
  font-family: "Museo";
  src: url("../fonts/Museo-300/Museo-300.eot");
  src: url("../fonts/Museo-300/Museo-300.woff") format("woff"),
    url("../fonts/Museo-300/Museo-300.ttf") format("truetype"),
    url("../fonts/Museo-300/Museo-300.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
