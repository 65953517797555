#dvs-plan .smart-garden {
  .window-popup-container {
    width: 100%;
    max-width: 800px;
  }

  .window-popup-content {
    padding: 0;
  }

  .smart-garden {
    display: flex;
    align-content: stretch;
    justify-content: flex-start;
    .icon {
      padding: 12px 14px 18px 17px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .smart-garden-img {
      padding: 12px 24px 18px 0;
    }
  }

  .smart-garden-img {
    --width: calc(316px - 24px + 8px);
    --height: calc(148px + 8px);
    --top: 36px;

    background: url("../../assets/smart-garden-picture-030423.svg") right
      calc(var(--top) + 12px) no-repeat;

    .img-offset {
      float: right;
      clear: right;
      &:nth-child(1) {
        height: 0;
        width: 0;
        margin-top: var(--top);
      }
      &:nth-child(2) {
        height: 20px;
        width: 125px;
      }
      &:nth-child(3) {
        height: 20px;
        width: 245px;
      }
      &:nth-child(4) {
        height: calc(var(--height) - 40px);
        width: var(--width);
      }
    }
  }

  strong {
    color: var(--active-color);
  }
  b {
    font-weight: bold;
  }

  .dialog-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 32px;
    button {
      padding: 7px 20px;
    }
    .ok {
      font-weight: bold;
      border-width: 2px;
    }
  }
}
